<template>
  <v-badge
    :value="badge"
    :icon="badgeIcon"
    :color="badgeColor"
    left
    :offset-x="minWidth - 30"
    offset-y="25"
  >
    <v-responsive
      :aspect-ratio="2 / 3"
      :max-width="maxWidth"
      :min-width="minWidth"
      class="pokercard-container flex-wrap flex-grow-0 flex-shrink-0"
    >
      <div class="pa-3" @click="click">
        <div
          class="pokercard"
          :class="{
            flipped: !visible
          }"
        >
          <div class="front">
            <v-container fill-height class="ma-0 pa-0">
              <v-row align="center" justify="center" class="middle-row text-truncate">
                <v-col>
                  <h1
                    class="font-weight-bold font-family-cera black--text"
                    :class="{
                      'text-h1': minWidth >= 240,
                      'text-h2': minWidth < 240 && minWidth >= 180,
                      'text-h3': minWidth < 180 && minWidth >= 150,
                      'text-h3': minWidth < 150 && minWidth > 120,
                      'text-h5': minWidth <= 120
                    }"
                    v-text="value.name"
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div class="back">
            <v-container fill-height class="ma-0 pa-0">
              <div class="pokercard-background secondary" />
            </v-container>
          </div>
        </div>
      </div>
    </v-responsive>
  </v-badge>
</template>

<script>
import { Card } from "../models/card";

export default {
  name: "PokerCard",

  props: {
    value: {
      type: Object,
      default: () => new Card()
    },

    visible: {
      type: Boolean,
      default: false
    },

    minWidth: {
      type: Number,
      default: 180
    },

    maxWidth: {
      type: Number,
      default: 250
    },

    badge: {
      type: Boolean,
      default: false
    },

    badgeColor: {
      type: String,
      default: "primary"
    },

    badgeIcon: {
      type: String,
      default: "mdi-volume-high"
    }
  },

  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
.pokercard-container {
  user-select: none;
  position: relative;
  perspective: 800px;

  &:first-child .pokercard .front,
  &:first-child .pokercard .back {
    background: #fff;
  }
}

.pokercard {
  position: absolute;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  transition: transform 0.4s;
  transform-style: preserve-3d;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 1em;

  &.flipped {
    transform: rotateY(180deg);
  }

  & .front,
  & .back {
    display: block;
    height: 100%;
    width: 100%;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    box-shadow: 3px 5px 20px 2px rgba(0, 0, 0, 0.25);
    border-radius: 1em;
  }

  & .back {
    transform: rotateY(180deg);
  }
}

.pokercard-background {
  height: 100%;
  width: 100%;
  background: url("/img/icons/mstile-310x310.png") no-repeat center;
  background-size: contain;
  border-radius: 1em;
}

.middle-row {
  margin: 0;
  height: calc(100% - max(30%, 80px));
}
</style>
