var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-badge',{attrs:{"value":_vm.badge,"icon":_vm.badgeIcon,"color":_vm.badgeColor,"left":"","offset-x":_vm.minWidth - 30,"offset-y":"25"}},[_c('v-responsive',{staticClass:"pokercard-container flex-wrap flex-grow-0 flex-shrink-0",attrs:{"aspect-ratio":2 / 3,"max-width":_vm.maxWidth,"min-width":_vm.minWidth}},[_c('div',{staticClass:"pa-3",on:{"click":_vm.click}},[_c('div',{staticClass:"pokercard",class:{
          flipped: !_vm.visible
        }},[_c('div',{staticClass:"front"},[_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"middle-row text-truncate",attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('h1',{staticClass:"font-weight-bold font-family-cera black--text",class:{
                    'text-h1': _vm.minWidth >= 240,
                    'text-h2': _vm.minWidth < 240 && _vm.minWidth >= 180,
                    'text-h3': _vm.minWidth < 180 && _vm.minWidth >= 150,
                    'text-h3': _vm.minWidth < 150 && _vm.minWidth > 120,
                    'text-h5': _vm.minWidth <= 120
                  },domProps:{"textContent":_vm._s(_vm.value.name)}})])],1)],1)],1),_c('div',{staticClass:"back"},[_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fill-height":""}},[_c('div',{staticClass:"pokercard-background secondary"})])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }